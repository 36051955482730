import { React } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import GlobalStyles from "styles/GlobalStyles";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// Components
// import Hero from "components/hero/BackgroundAsImage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/DashedBorderSixFeatures";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import GetStarted from "components/cta/GetStarted";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground";
import ContactUsLongForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ThreeColSimple from "components/features/ThreeColSimple";
// import ThreeColContactDetails from "components/cards/ThreeColContactDetails";
import TwoColWithButton from "components/features/TwoColWithButton";
import ThreeColWithSideImageWithPrimaryBackground from "components/features/ThreeColWithSideImageWithPrimaryBackground";

// Icons
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import CheckboxCircleIcon from "images/checkbox-circle.svg";

// Images
import customerSupportIllustrationSrc from "images/mj-faq.avif";
import logoSvg from "images/logo.svg";
import HouseVector from "images/mj-person-working.avif";
import Newsletter from "images/mj-newsletter.avif";
import Feature from "images/mj-data-input.avif";

// import { NavLink } from "react-router-dom";
// import ThreeColCenteredStatsPrimaryBackground from "components/features/ThreeColCenteredStatsPrimaryBackground";

const StyledLink = tw.a`border-b border-primary-500 border-dotted cursor-pointer hover:text-primary-500`;

const plans = {
  id: "packages",
  subheading: "",
  heading: "Subscription plans",
  description:
    "Upgrade when you need to. It's easy to expand and adapt your systems as your team grows or when setting up a new office, ensuring that your infrastructure scales seamlessly with your evolving needs.",
  plans: [
    {
      name: "Professional",
      price: ["£99", "/month"],
      oldPrice: "",
      description: "Perfect for real estate professionals.",
      features: [
        "1 user",
        "Property Management",
        "Newsletter signup",
        "Image upload",
        "50 current listings",
      ],
      url: "",
    },
    {
      name: "Single Branch",
      price: ["£199", "/month"],
      oldPrice: "",
      description:
        "Well-suited for a single real estate office. Includes all the features of the Professional plan.",
      features: [
        "5 users",
        "Property Management",
        "Newsletter signup",
        "Image upload",
        "250 Current Listings",
        "Additional user £10 per month",
      ],
      url: "",
    },
    {
      name: "Multi-Branch ",
      price: ["£399", "/month"],
      oldPrice: "",
      description:
        "Ideal for a multi-branch setup. Includes all the features of the Single Branch plan.",
      features: [
        "25 users",
        "Property Management",
        "Newsletter signup",
        "Image upload",
        "Mutibrand",
        "Unlimited Listings",
        "Additional user £5 per month",
      ],
      url: "",
    },
  ],
};

export default ({
  feature1 = {
    subheading: "",
    heading: (
      <div>
        Instantly publish your{" "}
        <span tw="text-primary-500">property listings.</span>
      </div>
    ),
    description: (
      <>
        <span>
          Estatia presents premium property listings in a template that is
          specifically and consistently designed to showcase your agency brand
          and listings.{" "}
        </span>
        <br />
        <span>
          It's easy to use, secure and progressive. Estatia is based on a fast
          backend admin system for real estate listings, with brand templated
          front-ends.{" "}
        </span>
        <br />
        <span>
          Estatia provides regular updates (on a 6 week cycle) that we roll-out
          to all users adding in new in-demand features. The site is
          multi-lingual (listings entered in English are rendered in the local
          language through translation). Estatia renders extremely fast (check
          out Google Lighthouse scores). The Admin system is multi-role, with a
          highly secure infrastructure.
        </span>
      </>
    ),
    primaryButtonText: "",
    primaryButtonUrl: "",
    imageSrc: Feature,
    buttonRounded: true,
    imageRounded: true,
    imageBorder: false,
    imageShadow: false,
    imageCss: null,
    imageDecoratorBlob: false,
    imageDecoratorBlobCss: null,
    textOnLeft: true,
  },

  newsletterSignUp = {
    subheading: "Sign Up",
    heading: (
      <>
        Feel free to <span tw="text-primary-500">get in touch</span>
        <wbr /> with us.
      </>
    ),
    description:
      "Be the first to know about this revolution in property listings.",
    submitButtonText: "Subscribe",
    formAction: "#",
    formMethod: "get",
    textOnLeft: true,
    footer:
      "By submitting this form you consent that we may contact you by email and you agree to our Terms and Conditions. We will not share your information with any third parties. You may opt out at any time.",
  },
  faqContent = {
    description:
      "Here are some frequently asked questions about our service from our customers. Should you have any other questions, feel free to reach out via the contact form below.",
    faqs: [
      {
        question: "How can I list a new property on the platform?",
        answer:
          'To list a new property, log in to your account, go to the "Manage Listings" section, and click on "Add New Property."',
      },
      {
        question: "How do I manage tenant communications through the app?",
        answer:
          "Our platform offers a built-in messaging system that allows you to communicate directly with tenants. ",
      },
      {
        question: "Can I upgrade my current subscription?",
        answer: "Yes, you can upgrade your current subscription at any time.",
      },
      {
        question: "Can I set up automated rent reminders for tenants?",
        answer: "Yes, you can set up automated rent reminders for tenants.",
      },
      {
        question: "How can I generate financial reports for my properties?",
        answer:
          'To generate financial reports, navigate to the "Reports" section of your dashboard. You can download or print the reports for your records.',
      },
    ],
  },
  banner1 = {
    header: "Expansion",
    text: "Our continuous improvement programme is responsive to customer suggestions. Rental Maintence managment and report an issue module is in development.",
    primaryLinkText: "Find out more",
    primaryLinkUrl: "#newsletter",
    secondaryLinkText: "Contact us",
    secondaryLinkUrl: "#contact-us",
  },
  banner2 = {
    id: "book-a-demo",
    header: "Booking your demo",
    text: (
      <>
        To view the software we provide a free overview online meeting. Please
        simply book an available slot and we will confirm your meeting by email
        so you can add it to your calendar. We send a reminder email 24 hours
        before the scheduled meeting.
        <br />
        If you need to cancel or reschedule, simply let us know.
      </>
    ),
    primaryLinkText: "",
    primaryLinkUrl: "",
    secondaryLinkText: "Book a demo",
    // secondaryLinkUrl: "#contact-us",
    googleSchedule: true,
  },
  moreForLess = {
    cards: [
      {
        imageSrc: ShieldIconImage,
        title: "Security, peace of mind",
        description:
          "Modern website management systems must be built security as a priority. The administration system assigns roles to each user. Each action taken by an user is authorised and logged. Accountability, authority and simplicity are central to the design of the system.",
      },
      {
        imageSrc: SupportIconImage,

        title: "Speed",
        description:
          "The software infrastructure is ahead of the game and focuses on speed and scaleable. This helps ensure your listings are found both by search engines and your potential buyers.",

        url: "https://google.com",
      },
      {
        imageSrc: CustomizeIconImage,
        title: "Affordable subscriptions",
        description:
          "Your subscription gives you access to your admin panel that loads your listings. Subscriptions are scaled according to your actual needs saving you money.",
        url: "https://reddit.com",
      },
    ],
    linkText: "",
    heading: "",
    subheading: "",
    description: "",
    imageContainerCss: null,
    imageCss: null,
  },
  moreForLess2 = {
    cards: [
      {
        imageSrc: CheckboxCircleIcon,
        title: "Engagement",
        description: "Buyer enquiries trigger automatic email responses",
      },
      {
        imageSrc: CheckboxCircleIcon,
        title: "New Features",
        description:
          "Your software constantly evolves with tested new features you can ask for",
      },
      {
        imageSrc: CheckboxCircleIcon,
        title: "Search",
        description: "Your customers can find your listings on search engines",
      },
      {
        imageSrc: CheckboxCircleIcon,
        title: "Cost effective",
        description:
          "Your costs for successful listings are reduced as you lessen the need for external subscriptions.",
      },
      {
        imageSrc: CheckboxCircleIcon,
        title: "Ease",
        description: "Your admin system is easy to understand and very fast",
      },
    ],
    heading: "More for less",
    subheading: "",
    description: "",
  },
  footer = {
    copytights: "© Estatia 2024. All Rights Reserved.",
    logo: logoSvg,
    logoText: "",
  },
  contactUsForm = {
    id: "newsletter",
    image: Newsletter,
    subheading: "Achieve listing dominance",
    url: "https://hook.eu2.make.com/2bvq0cllbgg9bmeyg2uyl2lru2jseap1",
    heading: (
      <>
        Be the first to know about this{" "}
        <span tw="text-primary-500">revolution in property listings.</span>
      </>
    ),
    description: (
      <>
        <span>
          We have a state-of-the-art development methodology that employs the
          best of the best used by major international companies. Our team
          regularly innovates new features and after extensive testing, we roll
          them out to our customers.{" "}
        </span>

        <span>
          If you are interested in registering for a demo before our software is
          released please enter your email address below. We will be in touch.
        </span>
      </>
    ),
    footer: (
      <>
        By submitting this form you consent that we may contact you by email and
        you agree to our{" "}
        <StyledLink href="/terms-conditions">Terms and Conditions</StyledLink>.
        We will not share your information with any third parties. You may opt
        out at any time.
      </>
    ),
  },
  hero = {
    heading: (
      <>
        Digital <span tw="text-primary-500">property management software</span>{" "}
        tailored for your business.
      </>
    ),
    roundedHeaderButton: true,
    image: HouseVector,
  },
}) => {
  return (
    <>
      <GlobalStyles />
      <div style={{ padding: "2rem" }}>
        <Hero {...hero} />
        {/* <Hero /> */}
        {/* <MainFeature /> */}
        {/* <CenteredGrid /> */}
        <TwoColWithButton {...feature1} />
      </div>
      <AnimationRevealPage>
        <Features />
        {/* <MainFeature2 /> */}
        {/* <Portfolio /> */}
        {/* <Testimonial
        subheading="Testimonials"
        heading={
          <>
            Our Clients <span tw="text-primary-500">Love Us.</span>
          </>
        }
        description="Here are what some of our amazing customers are saying about our marketing professionals. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        testimonials={[
          {
            imageSrc:
              "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Charlotte Hale",
            customerTitle: "CEO, Tesla Inc.",
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, Nestle",
          },
        ]}
        textOnLeft={true}
      /> */}
        <GetStarted {...banner1} />
        <ThreeColSimple {...moreForLess} />
        {/* <ThreeColContactDetails {...moreForLess2} /> */}
        <ThreeColWithSideImageWithPrimaryBackground {...moreForLess2} />
        <GetStarted {...banner2} />
        <FAQ
          description={faqContent.description}
          faqs={faqContent.faqs}
          imageSrc={customerSupportIllustrationSrc}
          imageContain={true}
          imageShadow={false}
          subheading="FAQs"
          heading={
            <>
              Do you have <span tw="text-primary-500">Questions ?</span>
            </>
          }
        />
        {/* <ContactUsForm id="newsletter" {...newsletterSignUp} image={Newsletter} /> */}
        {/* <ThreePlans /> */}
        <ThreePlansWithHalfPrimaryBackground {...plans} />
        {/* <Blog /> */}
        <ContactUsLongForm {...contactUsForm} />
      </AnimationRevealPage>
      <Footer {...footer} />
    </>
  );
};
