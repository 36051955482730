import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { handleWebhook } from "helpers/WebHooks";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const SmallText = tw.p`mt-4 text-xs leading-relaxed`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const Checkbox = tw.input`mr-4 mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

// const Textarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-24`}
// `;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  id = "",
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  image = EmailIllustrationSrc,
  url = "",
  footer = "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const handleSubmit = (e) => {
    const formElement = e.target.form;
    const formData = new FormData(formElement);

    const data = Object.fromEntries(formData.entries());

    // console.log("form data", data);

    if (url) {
      handleWebhook(url, data);
    }
  };
  return (
    <Container id={id}>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={image} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                autocomplete="on"
                aria-autocomplete="inline"
              />
              <Input type="text" name="full_name" placeholder="Full Name" />
              {/* <Input type="text" name="subject" placeholder="Subject" /> */}

              <div className="flex items-center mb-4">
                <Checkbox
                  id="news"
                  type="checkbox"
                  name="news"
                  className="w-4 h-4 mr-4 m-4 flex bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="news"
                  className="ms-2 mr-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Please keep me updated on any new release news.
                </label>
              </div>

              <div className="flex items-center mb-8">
                <Checkbox
                  id="marketing"
                  type="checkbox"
                  name="marketing"
                  className="w-4 h-4 mr-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="marketing"
                  className="ms-2 mr-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Please send me marketing materials.
                </label>
              </div>

              {/* <Textarea name="message" placeholder="Your Message Here" /> */}
              <SubmitButton type="button" onClick={handleSubmit}>
                {submitButtonText}
              </SubmitButton>
            </Form>
            <SmallText>{footer}</SmallText>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
